import React from 'react';
import { useTranslation } from 'next-i18next';

const CookieConsent = ({ buttonClass }) => {
  const { t } = useTranslation();

  return (
    <button className={buttonClass}>
      {t('CookieConsent.cookieSettingsButton')}
    </button>
  );
};

export default CookieConsent;
